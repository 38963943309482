import React from "react";
import { FormattedMessage } from "react-intl";

import AceImage from "./ace/PC image@3x.png";
import AceInsetIconURL from "./ace/PC image.png";

import HumanoidImage from "./humanoid/humanoid.png";
import HumanoidInsetIconURL from "./humanoid/humanoid-small.png";

export default [
    {
        name: "Playcomputer",
        extensionId: "pc",
        iconURL: AceImage,
        insetIconURL: AceInsetIconURL,
        //description: "Bisoft Playcomputer Kit",
        featured: true,
        disabled: false,
        internetConnectionRequired: false,
        bluetoothRequired: true,
        helpLink: "",
    },

    {
        name: "PC 1.0",
        extensionId: "onePointZero",
        iconURL: AceImage,
        insetIconURL: AceInsetIconURL,
        description: "",
        featured: true,
        disabled: false,
        internetConnectionRequired: false,
        bluetoothRequired: true,
    },

    {
        name: "Humanoid",
        extensionId: "humanoid",
        iconURL: HumanoidImage,
        insetIconURL: HumanoidInsetIconURL,
        description: "Bisoft Humanoid Kit",
        featured: true,
        disabled: false,
        internetConnectionRequired: false,
        bluetoothRequired: true,
        helpLink: "https://mryslab.github.io/s3-extend/",
    },
];
