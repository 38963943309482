import { defineMessages } from "react-intl";

export default defineMessages({
    backdrop: {
        defaultMessage: "backdrop{index}",
        description:
            "Default name for a new backdrop, scratch will automatically adjust the number if necessary",
        id: "gui.sharedMessages.backdrop",
    },
    costume: {
        defaultMessage: "costume{index}",
        description:
            "Default name for a new costume, scratch will automatically adjust the number if necessary",
        id: "gui.sharedMessages.costume",
    },
    sprite: {
        defaultMessage: "Sprite{index}",
        description:
            "Default name for a new sprite, scratch will automatically adjust the number if necessary",
        id: "gui.sharedMessages.sprite",
    },
    pop: {
        defaultMessage: "pop",
        description:
            "Name of the pop sound, the default sound added to a sprite",
        id: "gui.sharedMessages.pop",
    },
    replaceProjectWarning: {
        id: "gui.sharedMessages.replaceProjectWarning",
        defaultMessage:
            "Do you want to erase?  \n All your program process wil be erased.",
        description:
            "Confirmation that user wants to overwrite the current project contents",
    },
    loadFromComputerTitle: {
        id: "gui.sharedMessages.loadFromComputerTitle",
        defaultMessage: "Load from your computer",
        description: "Title for uploading a project from your computer",
    },
});
